import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CssBaseline,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import NavbarComponent from "../component/navbar-component";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/id";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const boxVariant = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  hidden: { opacity: 0, scale: 1 },
};

const BoxAnimate = ({ component }) => {
  // State definition
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <motion.div
      className="box"
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
    >
      {component}
    </motion.div>
  );
};

const FormDataPelanggan = () => {
  const navigate = useNavigate();

  // State definition
  const [pickValue, setPickValue] = useState(dayjs().add(1, "day"));
  const [outlets, setOutlets] = useState([]);

  // ===== Util Function =====
  // Normalize phone number input
  const normPhoneNumber = (event) => {
    let rawNumber = event.target.value;
    let numArr = Array.from(rawNumber);
    // console.log(rawNumber);

    if (numArr[0] === "0" && numArr.length > 1) {
      numArr.shift();
    }

    rawNumber = numArr.join("");

    return rawNumber;
  };

  // Normalize seat value
  const normSeatValue = (event) => {
    let rawNumber = event.target.value;
    let onlyNumber = rawNumber.replace(/\D/g, "");
    return onlyNumber;
  };

  // ===== UseEffect Function =====
  useEffect(() => {
    const savedBio = localStorage.getItem("savedBio");
    const savedBio_parsed = JSON.parse(savedBio);
    if (savedBio_parsed) {
      formik.setFieldValue("order_person", savedBio_parsed?.order_person);
      formik.setFieldValue("phone", savedBio_parsed?.phone);
      formik.setFieldValue("seat", savedBio_parsed?.seat);
      formik.setFieldValue("outletId", savedBio_parsed?.outletId);
    }

    // Get data Outlet
    axios({
      method: "get",
      url: `${
        process.env.REACT_APP_STATUS === "deployment"
          ? process.env.REACT_APP_BASE_URL
          : process.env.REACT_APP_BASE_URL_LOCAL
      }/seojun/api/outlet`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        const rawData = response.data;
        setOutlets(rawData.data);
      })
      .catch((err) => {});
  }, []);

  const formik = useFormik({
    initialValues: {
      order_person: "",
      phone: "",
      order_date: dayjs().add(1, "day").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      seat: 4,
      outletId: 1,
    },
    onSubmit: (values) => {
      let normalizeData = { ...values, phone: `+62${values.phone}` };
      localStorage.setItem("savedBio", JSON.stringify(values));

      // console.log(normalizeData);

      // kondisi kalau seat di bawah 4
      if (values.seat < 4) {
        // console.log(values.seat);
        Swal.fire({
          icon: "error",
          title: `Mohon Maaf ${String.fromCodePoint(0x1f64f)}`,
          html: `<small> Belum mencukupi jumlah minimal orang <br> Minimum reservasi, 4 orang. <small>`,
        });
      } else {
        // Kondisi untuk tidak melanjutkan ketika kuota di outlet dan tanggal penuh
        navigate("/menu");
      }
    },
  });

  return (
    <div>
      <CssBaseline />
      <BoxAnimate component={<NavbarComponent />} />

      {/* Outline */}
      <Box
        width="100vw"
        // height="90vh"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        p={1}
        my={1}
      >
        <Typography variant="h6" textAlign="center">
          Hello Sobat Seo Jun {String.fromCodePoint(0x1f44f)}, Selamat Datang di
          Form Reservasi Bukber Seo Jun
        </Typography>
        <Typography fontSize=".2" color="GrayText" textAlign="center" my={0.5}>
          Silakan isi data berikut ini untuk reservasi. <br /> Kami akan cek
          kesediaan tempat di outlet yang kamu pilih, kemudian kamu bisa pilih
          menu yang akan kamu nikmati saat berbuka nanti.
        </Typography>
        <Typography fontSize=".1" color="GrayText" textAlign="center" my={0.5}>
          Butuh info lebih lanjut,{" "}
          <a href="https://wa.me/+62882002670116?text=Hello%20Seo%20Jun%20Fried%20Chicken.%20Saya%20butuh%20informasi%20terkait%20reservasi.">
            hubungi admin
          </a>
          .
        </Typography>
        {/* End of Outline */}

        {/* Form */}
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              width: { xs: "100vw", sm: "23em", md: "23em", lg: "45em" },
              display: "flex",
              flexDirection: "column",
              my: 3,
              px: 2,
            }}
          >
            {/* Nama customer */}
            <Typography
              sx={{
                fontSize: ".8em",
                fontWeight: 500,
                textTransform: "capitalize",
                marginBottom: ".2em",
                color: "grey",
              }}
            >
              Nama Lengkap :
            </Typography>
            <TextField
              required
              fullWidth
              id="order_person"
              variant="outlined"
              size="small"
              color="primary"
              value={formik.values.order_person}
              onChange={formik.handleChange}
              InputLabelProps={{ style: { color: "#4F7170" } }}
            />

            {/* Nomor Handphone */}
            <Typography
              sx={{
                fontSize: ".8em",
                fontWeight: 500,
                textTransform: "capitalize",
                marginBottom: ".2em",
                marginTop: "1em",
                color: "grey",
              }}
            >
              Nomor Handphone :
            </Typography>
            <TextField
              required
              variant="outlined"
              id="phone"
              size="small"
              color="primary"
              value={formik.values.phone}
              onChange={(e) => {
                formik.setFieldValue("phone", normPhoneNumber(e));
              }}
              InputLabelProps={{ style: { color: "#4F7170" } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <font color="#4F7170">+62</font>
                  </InputAdornment>
                ),
              }}
            />

            {/* Jumlah Seat */}
            <Typography
              sx={{
                fontSize: ".8em",
                fontWeight: 500,
                textTransform: "capitalize",
                marginBottom: ".2em",
                marginTop: "1em",
                color: "grey",
              }}
            >
              Jumlah Orang :
            </Typography>
            <TextField
              required
              variant="outlined"
              id="seat"
              size="small"
              color="primary"
              type="number"
              value={formik.values.seat}
              onChange={(e) => {
                formik.setFieldValue("seat", normSeatValue(e));
              }}
            />

            {/* Outlet */}
            <Typography
              sx={{
                fontSize: ".8em",
                fontWeight: 500,
                textTransform: "capitalize",
                marginBottom: ".2em",
                marginTop: "1em",
                color: "grey",
              }}
            >
              Outlet :
            </Typography>
            <Select
              // required
              variant="outlined"
              id="outletId"
              size="small"
              color="primary"
              value={formik.values.outletId}
              onChange={(e) => {
                // setOutletId(e.target.value);
                formik.setFieldValue("outletId", e.target.value);
              }}
            >
              {outlets.map((outlet) => {
                return (
                  <MenuItem value={parseInt(outlet.outlet_id)} key={outlet.id}>
                    <Typography
                      sx={{
                        fontSize: ".8em",
                        fontWeight: 500,
                        textTransform: "capitalize",
                        padding: 2,
                        textOverflow: "clip",
                      }}
                    >
                      {outlet?.outlet} - {outlet?.address}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>

            {/* Tanggal */}
            <Typography
              sx={{
                fontSize: ".8em",
                fontWeight: 500,
                textTransform: "capitalize",
                marginBottom: ".2em",
                marginTop: "1em",
                color: "grey",
              }}
            >
              Tanggal reservasi :
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="id">
              <DateTimePicker
                id="order_date"
                timeSteps={{ minutes: 30 }}
                value={pickValue}
                onChange={(newValue) => {
                  setPickValue(newValue);
                  formik.setFieldValue(
                    "order_date",
                    `${dayjs(newValue).format("YYYY-MM-DDTHH:mm:ss.SSSZ")}`
                  );
                }}
                minDate={dayjs()}
              />
            </LocalizationProvider>
            <br />
            <Button
              variant="contained"
              color="success"
              // onClick={() => navigate("/menu")}
              type="submit"
            >
              Reservasi
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  );
};

export default FormDataPelanggan;
