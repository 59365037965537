import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./pages/homepage";
import NotFoundPage from "./pages/not-found-page";
import OrderPage from "./pages/order-page";
import FormBukberPage from "./pages/form-bukber";
import FormDataPelanggan from "./pages/form-data-pelanggan";
import FormReviewPage from "./pages/form-review-page";
import RingkasanPesanan from "./pages/ringakasan-pesanan";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/pre-order" element={<OrderPage />} />
        <Route path="/reservasi" element={<FormDataPelanggan />} />
        <Route path="/menu" element={<FormBukberPage />} />
        <Route path="/order-review" element={<FormReviewPage />} />
        <Route path="/invoice" element={<RingkasanPesanan />} />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
