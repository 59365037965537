import {
  Box,
  Typography,
  CssBaseline,
  Modal,
  Tabs,
  Tab,
  Grid,
  Fade,
} from "@mui/material";
// import { useFormik } from "formik";
import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import NavbarComponent from "../component/navbar-component";
import Footer from "../component/footer-component";
import "dayjs/locale/id";
import _ from "lodash";
import logo from "../assets/logo.png";
import dayjs from "dayjs";

// import * as Yup from "yup";
import axios from "axios";
import ListMenuItem from "../component/order-list-menu-item";
import EditVarianModal from "./form-edit-varian";
import BottomSheet from "../component/order-bottom-sheet";
import { useNavigate } from "react-router-dom";
// import ListMenuItem from "../component/order-list-menu-item";

const boxVariant = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  hidden: { opacity: 0, scale: 1 },
};

const BoxAnimate = ({ component }) => {
  // State definition
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <motion.div
      className="box"
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
    >
      {component}
    </motion.div>
  );
};

// Scroll to id
const gotoID = (id) => {
  // const violation = document.getElementById(id);
  const yOffset = -230;
  const element = document.getElementById(id);
  const y = element.getBoundingClientRect().top + window.scrollY + yOffset;

  window.scrollTo({ top: y, behavior: "smooth" });
};

const FormBukberPage = () => {
  const navigate = useNavigate();
  // State definition
  const [tabValue, setTabValue] = useState("seojun");
  const [menus, setMenus] = useState({
    seojun: [],
    sasmita: [],
    minuman: [],
  });
  const [openModal, setOpenModal] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState({});
  const [custData, setCustData] = useState({
    order_person: "Customer",
    phone: "[phone number]",
    order_date: "DD-MM-YYYY",
    seat: "[seat]",
  });
  const [openBottomSheet, set_openBottomSheet] = useState(true);
  const [itemSum, setItemSum] = useState(0);
  let scrollTimeout;

  // ==== Util Function ====
  const updateItemInfo = () => {
    let parsed_orders = JSON.parse(localStorage.getItem("orders")) || [];

    let itemList = [];
    for (let it in parsed_orders) {
      if (!itemList.includes(it)) {
        itemList.push(it);
      }
    }

    setItemSum(itemList.length);
  };

  // ===== Use Effect Function =====
  // Get menu item
  useEffect(() => {
    const savedBio = localStorage.getItem("savedBio");
    setCustData(JSON.parse(savedBio));

    updateItemInfo();

    axios({
      method: "get",
      url: `${
        process.env.REACT_APP_STATUS === "deployment"
          ? process.env.REACT_APP_BASE_URL
          : process.env.REACT_APP_BASE_URL_LOCAL
      }/seojun/api/menus`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        const rawData = response.data;
        setMenus(rawData.data);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      set_openBottomSheet(false);

      // Hapus timeout sebelumnya jika masih ada
      clearTimeout(scrollTimeout);

      // Set timeout untuk mendeteksi apakah scroll berhenti dalam 500ms
      scrollTimeout = setTimeout(() => {
        set_openBottomSheet(true);
      }, 500);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, []);

  // Input order
  const createOrder = () => {
    // Create order id
    let parse_savedBio = JSON.parse(localStorage.getItem("savedBio"));

    let orderId = `#${parse_savedBio.phone}#${dayjs(
      parse_savedBio.order_date
    ).format("DDMMYYYY")}#${parse_savedBio.seat}`;

    navigate("/order-review", { state: orderId });
  };

  return (
    <div className="body">
      <CssBaseline />
      <Box
        position="fixed"
        top={0}
        textAlign="center"
        width="100%"
        zIndex={1000}
      >
        <BoxAnimate component={<NavbarComponent />} />
        <Box backgroundColor="#AE2129" height="3em">
          <Typography
            sx={{
              fontSize: "1.2em",
              fontWeight: 700,
              color: "yellow",
              textTransform: "capitalize",
              textAlign: "center",
            }}
          >
            Daftar Menu
          </Typography>
        </Box>

        {/* TAB */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            justifyContent: "center",
            backgroundColor: "ivory",
            gap: 1,
          }}
        >
          <Tabs
            value={tabValue}
            onChange={(event, newVal) => {
              setTabValue(newVal);
              gotoID(newVal);
            }}
            textColor="secondary"
            indicatorColor="secondary"
          >
            {Object.keys(menus).map((brand) => {
              return <Tab label={brand} value={brand} key={brand} />;
            })}
          </Tabs>
        </Box>
      </Box>

      <BoxAnimate
        component={
          <Box
            minHeight="80vh"
            backgroundColor="#ae2129"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-start"
            paddingTop={{ xs: "50%", sm: "50%", md: "18%", lg: "12em" }}
          >
            <Box
              sx={{
                backgroundColor: "secondary.accent",
                borderRadius: 2,
                padding: 1,
                flexDirection: "column",
              }}
            >
              {/* Card Pesanan */}
              <Box
                sx={{
                  width: { xs: "100%", sm: "28em", md: "36em", lg: "40em" },
                  minWidth: "70vw",
                  minHeight: "90vh",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "ivory",
                  borderRadius: 2,
                  px: { xs: 1, sm: 1, md: 2.3, lg: 2.3 },
                  py: 0.1,
                }}
              >
                {/* LIST MENU */}
                <Box
                  sx={{
                    marginTop: "1em",
                    marginBottom: "1em",
                  }}
                >
                  {Object.keys(menus).length < 0 ||
                    Object.keys(menus).map((brand) => {
                      let brandName = String(brand);
                      return (
                        <div>
                          <Typography
                            id={brand}
                            fontSize={{
                              xs: "1em",
                              sm: "1em",
                              md: "1.2em",
                              lg: "1.2em",
                            }}
                            sx={{
                              textTransform: "capitalize",
                              marginBottom: "0.5em",
                              fontWeight: 700,
                            }}
                          >
                            {brand}
                          </Typography>
                          <Grid container gap={2}>
                            {menus[brandName].map((ele) => (
                              <ListMenuItem
                                key={ele.id}
                                item={ele}
                                order_input_func={() => {
                                  setOpenModal(true);
                                  setSelectedMenu(ele);
                                }}
                              />
                            ))}
                          </Grid>
                        </div>
                      );
                    })}
                </Box>
              </Box>
            </Box>
          </Box>
        }
      />

      {/* Bottom Sheet */}
      <Fade in={openBottomSheet} timeout={200}>
        <Box
          position="fixed"
          bottom={0}
          textAlign="center"
          width="100%"
          zIndex={1000}
          backgroundColor="ivory"
        >
          <BottomSheet
            order_person={custData.order_person}
            phone={custData.phone}
            order_date={custData.order_date}
            seat={custData.seat}
            item_sum={itemSum}
            reserve_func={createOrder}
          />
        </Box>
      </Fade>
      {/* MODAL */}
      <Modal
        open={openModal}
        onClose={() => {
          updateItemInfo();

          localStorage.removeItem("addon_data");
          localStorage.removeItem("varian_data");
          setOpenModal(false);
        }}
      >
        <EditVarianModal
          handleCloseFunc={() => {
            localStorage.removeItem("varian_data");
            localStorage.removeItem("addon_data");
            updateItemInfo();
            setOpenModal(false);
          }}
          item={selectedMenu}
        />
      </Modal>
      <Footer />
    </div>
  );
};

export default FormBukberPage;
