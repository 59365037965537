import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VarianComponent from "../component/varian-item-component";
import { useEffect, useState } from "react";
import axios from "axios";
import AddonComponent from "../component/addon-item-component";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const EditVarianModal = ({ item, handleCloseFunc }) => {
  // State definition
  const [dataVarian, setDataVarian] = useState({});
  const [dataAddon, setDataAddon] = useState({});
  const [price, setPrice] = useState(item?.price || 0);
  const [addon, setAddon] = useState([]);
  const [varian, setVarian] = useState([]);
  const [qty, setqty] = useState(1);

  // Fungsi untuk menambahkan item
  const storeItem = () => {
    // Create obeject untuk order
    let parsed_addon = JSON.parse(localStorage.getItem("addon_data")) || addon;
    let parsed_varian =
      JSON.parse(localStorage.getItem("varian_data")) || varian;
    let parsed_savedBio = JSON.parse(localStorage.getItem("savedBio")) || {};

    let order_arr = [];
    order_arr = JSON.parse(localStorage.getItem("orders")) || [];

    let augment = [...parsed_addon, ...parsed_varian];

    let orderObj = {};
    orderObj[item.sku_id] = {
      tmp_id: order_arr.length + 1,
      order_person: parsed_savedBio?.order_person,
      order_date: parsed_savedBio?.order_date,
      outletId: parsed_savedBio?.outletId,
      phone: `+62${parsed_savedBio?.phone}`,
      seat: parsed_savedBio?.seat,
      order_detail: augment,
      brand: item.brand,
      product_name: item.name,
      category: item.category,
      subtotal: price,
      qty: qty,
    };

    order_arr.push(orderObj);
    localStorage.setItem("orders", JSON.stringify(order_arr));
    localStorage.removeItem("varian_data");
    localStorage.removeItem("addon_data");

    // Close modal
    handleCloseFunc();
  };

  // Fungsi mengambil data addon
  const getAddon = (up) => {
    if (up) {
      let dataAddOn = localStorage.getItem("addon_data") || [];
      let parsed = JSON.parse(dataAddOn);

      let additional_price = 0;
      let nPrice = item?.price || 0;
      if (parsed.length > 0) {
        additional_price = parsed.reduce((acc, ele) => {
          return (acc += parseInt(ele.addon_price));
        }, 0);
        nPrice = nPrice + additional_price;
      } else {
        additional_price = 0;
      }

      setPrice(nPrice);
      setAddon(dataAddOn);
    }
  };

  // Fungsi mengambil data varian
  const getVarian = (up) => {
    if (up) {
      let dataVar = localStorage.getItem("varian_data");
      let parsed = JSON.parse(dataVar);
      setVarian(parsed);
    }
  };

  // ===== Use effect function ====
  useEffect(() => {
    // Get varians
    axios({
      method: "get",
      url: `${
        process.env.REACT_APP_STATUS === "deployment"
          ? process.env.REACT_APP_BASE_URL
          : process.env.REACT_APP_BASE_URL_LOCAL
      }/seojun/api/menus/${item?.sku_id}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        const rawData = response.data;
        setDataVarian(rawData.data);
      })
      .catch((err) => {});

    // Get varians
    axios({
      method: "get",
      url: `${
        process.env.REACT_APP_STATUS === "deployment"
          ? process.env.REACT_APP_BASE_URL
          : process.env.REACT_APP_BASE_URL_LOCAL
      }/seojun/api/menus/addon/${item?.sku_id}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        const rawData = response.data;
        setDataAddon(rawData.data);
      })
      .catch((err) => {});
  }, [item]);

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          maxHeight: "80%",
          bgcolor: "white",
          borderRadius: 2,
          boxShadow: 24,
          p: 3,
          overflowY: "scroll",
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
        >
          <IconButton onClick={handleCloseFunc}>
            <ArrowBackIcon />
          </IconButton>
          <Typography fontSize="1em" fontWeight={700}>
            Custom Pembelian
          </Typography>
        </Box>

        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="stretch"
          justifyContent="space-between"
        >
          <Box maxWidth="50%">
            <Typography
              fontSize="1em"
              my={1}
              fontWeight={700}
              textOverflow="clip"
            >
              {item.name}
            </Typography>
          </Box>
          <Typography fontSize="1em" my={1} fontWeight={700}>
            {item.price}
          </Typography>
        </Box>

        {/* Varian */}
        {Object.keys(dataVarian).length > 0 ? (
          <div key={dataVarian}>
            {Object.keys(dataVarian).map((vari) => {
              return (
                <>
                  <Divider />
                  <Typography
                    fontSize="1em"
                    fontWeight={700}
                    textTransform="capitalize"
                    my={1}
                  >
                    {vari}
                    <Typography variant="subtitle2">
                      maximum pilih {dataVarian[vari].max}
                    </Typography>
                  </Typography>

                  <VarianComponent
                    max_var={dataVarian[vari].max}
                    list={dataVarian[vari].list}
                    add_func={(up) => getVarian(up)}
                  />
                </>
              );
            })}
          </div>
        ) : (
          <div></div>
        )}

        <br />

        {/* Addon */}
        {Object.keys(dataAddon).length > 0 ? (
          <div key={dataAddon}>
            {Object.keys(dataAddon).map((addo) => {
              return (
                <>
                  <Divider />
                  <Typography
                    fontSize="1em"
                    fontWeight={700}
                    textTransform="capitalize"
                    my={1}
                  >
                    {addo}
                    <Typography variant="subtitle2">
                      maximum pilih {dataAddon[addo].max}
                    </Typography>
                  </Typography>

                  <AddonComponent
                    max_var={dataAddon[addo].max}
                    list={dataAddon[addo].list}
                    add_func={(up) => getAddon(up)}
                  />
                </>
              );
            })}
          </div>
        ) : (
          <div></div>
        )}

        <br />

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body1" fontWeight={700}>
            Jumlah Pembelian
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              size="small"
              onClick={() => {
                setqty((prev) => (prev - 1 <= 1 ? 1 : prev - 1));
              }}
            >
              <RemoveCircleIcon color="primary" width="1em" />
            </IconButton>
            <Typography
              fontSize={{ xs: ".9em", sm: ".9em", md: "1em", lg: "1em" }}
              sx={{
                fontWeight: 700,
              }}
            >
              {qty}
            </Typography>
            <IconButton
              size="small"
              onClick={() => {
                setqty((prev) => prev + 1);
              }}
            >
              <AddCircleIcon color="primary" width="1em" />
            </IconButton>
          </Box>
        </Box>
        <br />
        <Button fullWidth variant="contained" onClick={storeItem}>
          {qty * price} - Tambah pembelian
        </Button>
      </Box>
    </>
  );
};

export default EditVarianModal;
