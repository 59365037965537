import { Box, Divider, Radio, RadioGroup, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";

const ChildComp = ({ name, desc, price, value }) => {
  return (
    <>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        alignItems="space-between"
        justifyContent="space-between"
        gap={1}
        my={1}
      >
        <Radio flex={0.1} value={value} />
        <>
          {desc ? (
            <Box flex={3} maxWidth="60%" textOverflow="clip">
              <Typography fontSize=".8em">{name}</Typography>
              <Typography fontSize=".75em">{desc}</Typography>
            </Box>
          ) : (
            <Box
              flex={3}
              display="flex"
              flexDirection="column"
              maxWidth="60%"
              textOverflow="clip"
              alignItems="flex-start"
              justifyContent="center"
            >
              <Typography fontSize=".9em">{name}</Typography>
            </Box>
          )}
        </>

        <Typography flex={1} textAlign="right">
          {price}
        </Typography>
      </Box>
      <Divider />
    </>
  );
};

const CheckBoxComp = ({ name, desc, price, value, onChange }) => {
  return (
    <>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        alignItems="space-between"
        justifyContent="space-between"
        gap={1}
        my={1}
      >
        <Checkbox flex={0.1} checked={value} onChange={onChange} />
        <>
          {desc ? (
            <Box flex={3} maxWidth="60%" textOverflow="clip">
              <Typography fontSize=".8em">{name}</Typography>
              <Typography fontSize=".75em">{desc}</Typography>
            </Box>
          ) : (
            <Box
              flex={3}
              display="flex"
              flexDirection="column"
              maxWidth="60%"
              textOverflow="clip"
              alignItems="flex-start"
              justifyContent="center"
            >
              <Typography fontSize=".9em">{name}</Typography>
            </Box>
          )}
        </>

        <Typography flex={1} textAlign="right">
          {price}
        </Typography>
      </Box>
      <Divider />
    </>
  );
};

const AddonComponent = ({ max_var, add_func, list }) => {
  const [selectedVar, setSelectedVar] = useState([]);
  const [selectedBox, setSelectedBox] = useState([]);

  const changeVar = (e) => {
    setSelectedVar(e.target.value);

    let val = e.target.value;

    let filter = list.filter((ele) => {
      return ele.addon_id === val;
    });

    tempStore(filter[0]);
    add_func(true);
  };

  const handleChangeBox = (value) => {
    setSelectedBox((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
    );
  };

  // ==== util function ====
  const tempStore = (addonData) => {
    let arrAddOn = JSON.parse(localStorage.getItem("addon_data")) || [];

    let filter = arrAddOn.filter((ele) => {
      return ele.addon_type === addonData.addon_type;
    });

    if (filter.length < 1) {
      let tArr = arrAddOn;
      tArr.push(addonData);

      localStorage.setItem("addon_data", JSON.stringify(tArr));
    } else {
      let tArr = arrAddOn.filter((ele) => {
        return ele.addon_type !== addonData.addon_type;
      });
      tArr.push(addonData);

      localStorage.setItem("addon_data", JSON.stringify(tArr));
    }
  };

  // === UseEffect Function ===
  useEffect(() => {
    let ad = localStorage.getItem("addon_data");
    let addOnData = JSON.parse(ad) || [];

    let selectedArr = [];
    if (selectedBox.length > 0) {
      selectedBox.forEach((s) => {
        let selectedObj = list.filter((ele) => {
          return ele.addon_id === s;
        });
        selectedArr.push(selectedObj[0]);
      });

      let tArr = addOnData.filter((ele) => {
        return ele.addon_type !== selectedArr[0]?.addon_type;
      });
      tArr.push(...selectedArr);

      // console.log(tArr);
      localStorage.setItem("addon_data", JSON.stringify(tArr));
    } else {
      let tArr = addOnData.filter((ele) => {
        return (
          ele.addon_type != "extra_saus" && ele.addon_type != "extra_sambal"
        );
      });
      localStorage.setItem("addon_data", JSON.stringify(tArr));
    }
    // Inform get addon function
    add_func(true);
  }, [selectedBox]);

  return (
    <Box>
      {max_var < 2 ? (
        <RadioGroup value={selectedVar} onChange={changeVar}>
          {list.map((ele) => (
            <ChildComp
              key={ele.addon_id}
              name={ele.addon_item}
              price={ele.addon_price}
              value={ele.addon_id}
            />
          ))}
        </RadioGroup>
      ) : (
        <>
          {list.map((ele) => {
            return (
              <CheckBoxComp
                key={ele.addon_id}
                name={ele.addon_item}
                price={ele.addon_price}
                value={selectedBox.includes(ele.addon_id)}
                onChange={() => handleChangeBox(ele.addon_id)}
              />
            );
          })}
        </>
      )}
    </Box>
  );
};

export default AddonComponent;
