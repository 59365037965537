import { Box, Divider, Radio, RadioGroup, Typography } from "@mui/material";
import { useState } from "react";

const ChildComp = ({ name, desc, price, value }) => {
  return (
    <>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        alignItems="space-between"
        justifyContent="space-between"
        gap={1}
        my={1}
      >
        <Radio flex={0.1} value={value} />
        <>
          {desc ? (
            <Box flex={3} maxWidth="60%" textOverflow="clip">
              <Typography fontSize=".8em">{name}</Typography>
              <Typography fontSize=".75em">{desc}</Typography>
            </Box>
          ) : (
            <Box
              flex={3}
              display="flex"
              flexDirection="column"
              maxWidth="60%"
              textOverflow="clip"
              alignItems="flex-start"
              justifyContent="center"
            >
              <Typography fontSize=".9em">{name}</Typography>
            </Box>
          )}
        </>

        <Typography flex={1} textAlign="right">
          {price}
        </Typography>
      </Box>
      <Divider />
    </>
  );
};

const VarianComponent = ({ max_var, add_func, list }) => {
  const [selectedVar, setSelectedVar] = useState("");

  const changeVar = (e) => {
    setSelectedVar(e.target.value);

    let val = e.target.value;

    let filter = list.filter((ele) => {
      return ele.varian_id === val;
    });

    tempStore(filter[0]);
    add_func(true);
  };

  // ===== Util Function =====
  const tempStore = (varData) => {
    let arrVar = JSON.parse(localStorage.getItem("varian_data")) || [];

    let filter = arrVar.filter((ele) => {
      return ele.varian_type === varData.varian_type;
    });

    if (filter.length < 1) {
      let tArr = arrVar;
      tArr.push(varData);

      localStorage.setItem("varian_data", JSON.stringify(tArr));
    } else {
      let tArr = arrVar.filter((ele) => {
        return ele.varian_type !== varData.varian_type;
      });
      tArr.push(varData);

      localStorage.setItem("varian_data", JSON.stringify(tArr));
    }
  };
  return (
    <Box>
      {max_var < 2 ? (
        <RadioGroup value={selectedVar} onChange={changeVar}>
          {list.map((ele) => (
            <ChildComp
              key={ele.varian_id}
              name={ele.name}
              desc={ele.desc}
              price={ele.varian_price}
              value={ele.varian_id}
            />
          ))}
        </RadioGroup>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default VarianComponent;
