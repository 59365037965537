import {
  Box,
  Button,
  CssBaseline,
  Divider,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import NavbarComponent from "../component/navbar-component";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
import Swal from "sweetalert2";

const boxVariant = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  hidden: { opacity: 0, scale: 1 },
};

const BoxAnimate = ({ component }) => {
  // State definition
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <motion.div
      className="box"
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
    >
      {component}
    </motion.div>
  );
};

const FormReviewPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [orders, setOrders] = useState([]);
  const [bio, setBio] = useState({
    order_date: dayjs().format("DD-MM-YYYY"),
    order_person: "[order_person]",
    outletId: "[outletid]",
    phone: "[phone]",
    seat: "4",
  });
  const [rBio, setRBio] = useState({});
  const [outlets, setOutlets] = useState([]);

  const deleteItem = (product_sku) => {
    let pOrders = JSON.parse(localStorage.getItem("orders"));

    let nArr = pOrders.filter((ob) => {
      let kOb = Object.keys(ob);
      return kOb[0] != product_sku;
    });
    // console.log("sku", product_sku);
    setOrders(nArr);
    localStorage.setItem("orders", JSON.stringify(nArr));
  };

  const createWAObj = (bio) => {
    // console.log(bio);
    let py = `Hello Saya Ingin Reservasi, berikut data dan pesanan saya :\n
      Nama: ${bio["Nama"]} 
      Kontak: ${bio["Kontak"]} 
      Tanggal Reservasi: ${dayjs(rBio["order_date"]).format(
        "DD-MM-YYYY HH:MM"
      )} 
      Seat: ${bio["Seat"]} 
      Outlet: ${bio["Outlet"]}
      Link Pesanan : https://seojunfriedchicken.com/invoice?o=${encodeURIComponent(
        location.state
      )}
      `;
    return py;
  };

  const proceed = () => {
    // Cek jumlah qty makanan berbanding jumlah seat
    let estSeat = 0;
    let fM = orders.filter((ele) => {
      let k = Object.keys(ele)[0];
      return ele[k]?.category != "minuman";
    });

    estSeat = fM.reduce((accu, ele) => {
      let k = Object.keys(ele)[0];
      return (accu += ele[k]?.qty);
    }, 0);

    if (parseInt(bio["Seat"]) <= estSeat) {
      Swal.fire({
        title: "Pesanan Sudah Benar ?",
        showDenyButton: true,
        confirmButtonText: "Ya Pesan",
        denyButtonText: `Cek Lagi`,
      }).then((result) => {
        if (result.isConfirmed) {
          let values = {
            bio: rBio,
            orders: orders,
            order_id: location.state,
          };

          axios({
            method: "post",
            url: `${
              process.env.REACT_APP_STATUS === "deployment"
                ? process.env.REACT_APP_BASE_URL
                : process.env.REACT_APP_BASE_URL_LOCAL
            }/seojun/api/order?o=${encodeURIComponent(location.state)}`,
            data: values,
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          })
            .then((resp) => {
              if (resp) {
                let wa_payload = createWAObj(bio);
                let waPayload_encoded = encodeURIComponent(wa_payload);

                Swal.fire({
                  title: `Terimakasih Sudah Reservasi ${String.fromCodePoint(
                    0x1f64f
                  )}`,
                  html: `<small> Konfirmasi ke admin kami untuk proses selanjutnya.<small>`,
                }).then((r) => {
                  if (r) {
                    window.open(
                      `https://wa.me/+62882002670116?text=${waPayload_encoded}`
                    );
                  }
                });

                // Hapus orders dan savedBio
                localStorage.removeItem("savedBio");
                localStorage.removeItem("orders");
                navigate("/reservasi");
              } else {
                Swal.fire("Mohon Maaf", "Ada Kesalahan", "error");
              }
            })
            .catch((err) => {
              Swal.fire("Mohon Maaf", "Ada Kesalahan", "error");
            });
        }
      });
    } else {
      Swal.fire({
        title: `Mohon Maaf ${String.fromCodePoint(0x1f64f)}`,
        html: `<small> Jumlah makanan, harus sama atau lebih dari seat yang dipesan <small>`,
        showDenyButton: false,
        confirmButtonText: "Tambah Pesanan",
      });
    }

    // let wa_payload = py;
    // console.log(createWAObj(bio));
    // let wa_payload = createWAObj(bio, orders);
    // let waPayload_encoded = encodeURIComponent(`http://rpasukahati.com`);
    // window.open(`https://wa.me/+6281221002666?text=${waPayload_encoded}`);

    // kirim ke database

    // navigate ke wa
  };

  // ==== Use Effect Function ====
  useEffect(() => {
    axios({
      method: "get",
      url: `${
        process.env.REACT_APP_STATUS === "deployment"
          ? process.env.REACT_APP_BASE_URL
          : process.env.REACT_APP_BASE_URL_LOCAL
      }/seojun/api/outlet`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        const rawData = response.data;
        setOutlets(rawData.data);
        // console.log(rawData);

        let sBio = JSON.parse(localStorage.getItem("savedBio"));
        setOrders(JSON.parse(localStorage.getItem("orders")));

        let sOutlet = rawData.data.filter((ele) => {
          return parseInt(ele.outlet_id) === parseInt(sBio.outletId);
        });

        // console.log(sOutlet);
        setBio({
          Nama: sBio.order_person,
          Kontak: `+62${sBio.phone}`,
          "Tanggal Reservasi": dayjs(sBio.order_date).format(
            "DD-MM-YYYY HH:MM"
          ),
          Seat: sBio.seat,
          Outlet: `${sOutlet[0]?.outlet} - ${sOutlet[0]?.address}`,
        });

        setRBio(sBio);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // useEffect(() => {}, []);

  return (
    <div className="body">
      <CssBaseline />
      <BoxAnimate component={<NavbarComponent />} />

      <Paper
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 2,
          marginBottom: ".6em",
        }}
      >
        {/* Invoice */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              fontSize="1em"
              fontWeight="bold"
              gutterBottom
              className="mt-4"
            >
              Pesanan Anda
            </Typography>

            <Button onClick={() => navigate("/menu")}>Tambah</Button>
          </Box>

          <Box>
            {orders.map((item) => (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="space-between"
                key={item[Object.keys(item)]}
              >
                <Box
                  key={item[Object.keys(item)]}
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="center"
                  textOverflow="clip"
                  maxWidth="60%"
                  flex={3}
                >
                  <Typography fontSize="1em" fontWeight={700}>
                    {item[Object.keys(item)].product_name}
                  </Typography>
                  <Typography textTransform="uppercase" fontSize=".8em">
                    {item[Object.keys(item)].brand}
                  </Typography>
                  <Box marginBottom={0.5}>
                    {item[Object.keys(item)].order_detail.map((ele) => {
                      return (
                        <>
                          <Typography fontSize=".8em" fontWeight={700}>
                            {ele.name}
                          </Typography>
                        </>
                      );
                    })}
                  </Box>
                  <>
                    {item[Object.keys(item)].order_detail.map((ele) => {
                      return (
                        <>
                          {ele.addon_type ? (
                            <Typography
                              textTransform="capitalize"
                              fontSize=".8em"
                              fontWeight={700}
                            >
                              {ele.addon_type} - {ele.addon_item}
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })}
                  </>

                  <br />
                </Box>
                <Typography flex={0.5}>
                  x{item[Object.keys(item)].qty}
                </Typography>
                <Typography flex={0.5}>
                  {item[Object.keys(item)].subtotal}
                </Typography>
                <IconButton size="small">
                  <DeleteIcon
                    fontSize="smaller"
                    color="error"
                    onClick={() => deleteItem(Object.keys(item))}
                  />
                </IconButton>
              </Box>
            ))}
            <Divider width="100%" />
          </Box>

          <Typography variant="h6" fontWeight="bold" className="mt-4">
            Total: Rp{" "}
            {orders.reduce((accu, ele) => {
              return (accu +=
                ele[Object.keys(ele)].subtotal * ele[Object.keys(ele)].qty);
            }, 0)}
          </Typography>
        </Box>
        <br />
        <Box width="100%">
          <Typography fontWeight={700}>Data Pemesan</Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography>Order ID : </Typography>
            <Typography color="green">{location.state} </Typography>
          </Box>
          {Object.keys(bio).map((d) => {
            return (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography>{d}: </Typography>
                <Typography
                  textAlign="right"
                  maxWidth="60%"
                  textOverflow="clip"
                >
                  {bio[d]}{" "}
                </Typography>
              </Box>
            );
          })}
        </Box>
        <br />
        <Button fullWidth variant="contained" onClick={proceed}>
          reservasi
        </Button>
      </Paper>
    </div>
  );
};

export default FormReviewPage;
