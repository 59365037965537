import {
  Box,
  Button,
  CssBaseline,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import NavbarComponent from "../component/navbar-component";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
import html2canvas from "html2canvas";

const boxVariant = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  hidden: { opacity: 0, scale: 1 },
};

const BoxAnimate = ({ component }) => {
  // State definition
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <motion.div
      className="box"
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
    >
      {component}
    </motion.div>
  );
};

const RingkasanPesanan = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const order_id = params.get("o");
  const [orders, setOrders] = useState([]);
  const [bio, setBio] = useState({
    order_date: dayjs().format("DD-MM-YYYY"),
    order_person: "[order_person]",
    outletId: "[outletid]",
    phone: "[phone]",
    seat: "4",
  });
  const pageRef = useRef(null);

  const handleScreenshot = async () => {
    if (pageRef.current) {
      const canvas = await html2canvas(pageRef.current, { useCORS: true });
      const image = canvas.toDataURL("image/png");

      // Buat link untuk download gambar
      const link = document.createElement("a");
      link.href = image;
      link.download = `invoice${order_id}.png`;
      link.click();
    }
  };

  // ====== Use Effect ======
  useEffect(() => {
    axios({
      method: "get",
      url: `${
        process.env.REACT_APP_STATUS === "deployment"
          ? process.env.REACT_APP_BASE_URL
          : process.env.REACT_APP_BASE_URL_LOCAL
      }/seojun/api/outlet`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }).then((res) => {
      const outletData = res.data.data;

      axios({
        method: "get",
        url: `${
          process.env.REACT_APP_STATUS === "deployment"
            ? process.env.REACT_APP_BASE_URL
            : process.env.REACT_APP_BASE_URL_LOCAL
        }/seojun/api/order?o=${encodeURIComponent(order_id)}`,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }).then((response) => {
        let rawData = response.data.data;

        let sOutlet = outletData.filter((ele) => {
          return (ele.outlet_id = parseInt(rawData.outlet_id));
        });

        setBio({
          Nama: rawData.order_person,
          Kontak: `+62${rawData.phone}`,
          "Tanggal Reservasi": dayjs(rawData.order_date).format(
            "DD-MM-YYYY HH:MM"
          ),
          Seat: rawData.seat,
          Outlet: `${sOutlet[0]?.outlet} - ${sOutlet[0]?.address}`,
        });

        setOrders(JSON.parse(rawData.order_details));
      });
    });
  }, []);

  return (
    <div className="body">
      <CssBaseline />
      <BoxAnimate component={<NavbarComponent />} />
      <Button fullWidth onClick={handleScreenshot}>
        Download
      </Button>
      <Paper
        ref={pageRef}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 2,
          marginBottom: ".6em",
        }}
      >
        {/* Invoice */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              fontSize="1em"
              fontWeight="bold"
              gutterBottom
              className="mt-4"
              color="green"
            >
              Ringkasan Pesanan SEO JUN Fried Chicken
            </Typography>
          </Box>

          <Box>
            {orders.map((item) => (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="space-between"
                key={item[Object.keys(item)]}
              >
                <Box
                  key={item[Object.keys(item)]}
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="center"
                  textOverflow="clip"
                  maxWidth="60%"
                  flex={3}
                >
                  <Typography fontSize="1em" fontWeight={700}>
                    {item[Object.keys(item)].product_name}
                  </Typography>
                  <Typography textTransform="uppercase" fontSize=".8em">
                    {item[Object.keys(item)].brand}
                  </Typography>
                  <Box marginBottom={0.5}>
                    {item[Object.keys(item)].order_detail.map((ele) => {
                      return (
                        <>
                          <Typography fontSize=".8em" fontWeight={700}>
                            {ele.name}
                          </Typography>
                        </>
                      );
                    })}
                  </Box>
                  <>
                    {item[Object.keys(item)].order_detail.map((ele) => {
                      return (
                        <>
                          {ele.addon_type ? (
                            <Typography
                              textTransform="capitalize"
                              fontSize=".8em"
                              fontWeight={700}
                            >
                              {ele.addon_type} - {ele.addon_item}
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })}
                  </>

                  <br />
                </Box>
                <Typography flex={0.5}>
                  x{item[Object.keys(item)].qty}
                </Typography>
                <Typography flex={0.5}>
                  {item[Object.keys(item)].subtotal}
                </Typography>
              </Box>
            ))}
            <Divider width="100%" />
          </Box>

          <Typography variant="h6" fontWeight="bold" className="mt-4">
            Total: Rp{" "}
            {orders.reduce((accu, ele) => {
              return (accu += ele[Object.keys(ele)].subtotal);
            }, 0)}
          </Typography>
        </Box>
        <br />
        <Box width="100%">
          <Typography fontWeight={700}>Data Pemesan</Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography>Order ID : </Typography>
            <Typography color="green">{order_id} </Typography>
          </Box>
          {Object.keys(bio).map((d) => {
            return (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography>{d}: </Typography>
                <Typography
                  textAlign="right"
                  maxWidth="60%"
                  textOverflow="clip"
                >
                  {bio[d]}{" "}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Paper>
    </div>
  );
};

export default RingkasanPesanan;
