import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import { useState } from "react";

const ListMenuItem = ({ item, order_input_func }) => {
  const [orderItem, setOrderItem] = useState(0);

  return (
    <div>
      <Paper
        sx={{
          width: { xs: "100%", sm: "25em", md: "30em", lg: "30em" },
          minWidth: { xs: "22em", sm: "22.5em", md: "100%", lg: "100%" },
          maxWidth: { xs: "22.5em", sm: "22.5em", md: "100%", lg: "100%" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 1.2,
          marginBottom: ".6em",
        }}
      >
        {item ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "stretch",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                flex: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                fontSize={{ xs: ".9em", sm: ".9em", md: "1em", lg: "1em" }}
                sx={{
                  fontWeight: 700,
                  textTransform: "capitalize",
                }}
              >
                {item.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: ".7em",
                  fontWeight: 300,
                  marginTop: ".3em",
                }}
              >
                {item.desc}
              </Typography>

              <Typography
                fontSize={{ xs: ".9em", sm: ".9em", md: "1em", lg: "1em" }}
                sx={{
                  fontWeight: 700,
                  marginTop: ".4em",
                }}
              >
                {item.price}
              </Typography>
            </Box>
            <Box
              maxHeight={100}
              maxWidth={100}
              sx={{
                flex: 1,
                backgroundColor: "aliceblue",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                objectFit: "inherit",
                overflow: "hidden",
                borderRadius: 4,
              }}
            >
              <img
                // src={`https://seojunfriedchicken.com/public/menu/${item.id}.webp`}
                src={`${
                  process.env.REACT_APP_STATUS === "deployment"
                    ? process.env.REACT_APP_BASE_URL
                    : process.env.REACT_APP_BASE_URL_LOCAL
                }/public/imgs/${item.sku_id}.webp`}
                alt="menu"
                width="100px"
                height="100px"
              />
            </Box>
          </Box>
        ) : (
          <Box>Loading . . </Box>
        )}

        {/* Tombol untuk tambah pesanan */}
        <br />
        <Divider
          width="100%"
          height="2px"
          sx={{
            marginTop: ".4em",
            marginbottom: ".4em",
          }}
        />
        <Box>
          <Button
            variant="outlined"
            sx={{
              fontSize: ".65em",
              borderRadius: 8,
              marginTop: "1em",
              marginBottom: ".4em",
            }}
            onClick={() => order_input_func(item)}
          >
            tambah
          </Button>
        </Box>
      </Paper>
    </div>
  );
};

export default ListMenuItem;
