import React, { useState } from "react";
import { SwipeableDrawer, Box, Typography, Button } from "@mui/material";
import dayjs from "dayjs";

const BottomSheet = ({
  order_person,
  phone,
  order_date,
  seat,
  item_sum,
  reserve_func,
}) => {
  return (
    <>
      <Box
        sx={{
          height: "8em",
          overflowY: "auto",
          p: 1,
        }}
      >
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          flexDirection="row"
          gap={1}
          px={2}
          my={1}
        >
          <Box display="flex" alignItems="flex-start" flexDirection="column">
            <Typography variant="subtitle1" textTransform="capitalize">
              {order_person}
            </Typography>
            <Typography variant="subtitle2" textTransform="capitalize">
              +62{phone}
            </Typography>
          </Box>
          <Box display="flex" alignItems="flex-end" flexDirection="column">
            <Typography variant="subtitle1" textTransform="capitalize">
              {dayjs(order_date).format("DD-MM-YYYY HH:MM")}
            </Typography>
            <Typography variant="subtitle2" textTransform="capitalize">
              {seat} orang
            </Typography>
          </Box>
        </Box>

        <Button
          variant="contained"
          color="success"
          sx={{ borderRadius: "16px" }}
          fullWidth
          disabled={!(item_sum > 0)}
          onClick={reserve_func}
        >
          {item_sum > 0 ? `${item_sum} item - reservasi` : `Belum Ada Item`}
        </Button>
      </Box>
      {/* <button onClick={() => setOpen(true)}>Buka Bottom Sheet</button>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        disableBackdropTransition
      >
        <Box
          sx={{
            height: 300,
            overflowY: "auto",
            p: 2,
          }}
          // onScroll={(e) => {
          //   if (e.target.scrollTop > 50) {
          //     setOpen(false);
          //   }
          // }}
        >
          <Typography variant="h6">ti</Typography>
          <p>Scroll ke bawah untuk menutup...</p>
          <p style={{ height: 500 }}>Tambahkan konten panjang di sini...</p>
        </Box>
      </SwipeableDrawer> */}
    </>
  );
};

export default BottomSheet;
